import { useState, useEffect, useCallback, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  SelectorIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { Transition, Listbox } from "@headlessui/react";
import { toast } from 'react-toastify';
import request from 'api/http-request';
import moment from 'moment';
import { ExclamationIcon } from '@heroicons/react/outline';
import PageHead from 'Components/PageHead';
import Modal from 'Components/Modal';
import MonthCalendar from 'Components/MonthCalendar';
import DayCalendar from 'Components/DayCalendar';
import { checkAuthority } from 'api/checkAuthority';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const boardName = '시설대관';
const boardBaseUrl = '/rent';
const boardDescription = '';

export default function Rent() {
  const [searchParams, setSearchParams] = useSearchParams({ view: 'month' });
  const view = searchParams.get('view');

  const [rooms, setRooms] = useState([]);
  const [roomId, setRoomId] = useState(1);
  const [rentableDate, setRentableDate] = useState({});
  const [days, setDays] = useState([]);
  const [myRents, setMyRents] = useState([]);
  const [adminRents, setAdminRents] = useState([]);
  const [adminMonth, setAdminMonth] = useState(moment().format('YYYY-MM'));
  const [adminRoomId, setAdminRoomId] = useState(1);
  const [adminRentsFiltered, setAdminRentsFiltered] = useState([]);
  const [adminClubs, setAdminClubs] = useState({});
  const [adminClubId, setAdminClubId] = useState(0);
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [cancelModal, setCancelModal] = useState(false);
  const [selectedRentId, setSelectedRentId] = useState(undefined);

  const getDays = useCallback(() => {
    (async () => {
      let roomsTemp = [];
      const getRoomResult = await request({
        method: 'GET',
        url: boardBaseUrl
      });

      if (getRoomResult && getRoomResult.success) {
        setRooms(getRoomResult.rooms);
        roomsTemp = getRoomResult.rooms;
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${getRoomResult.error.code} - ${getRoomResult.error.message}`}</p></div>, { type: 'error' });
      }

      let rentableDateTemp = {};
      const rentableResult = await request({
        method: 'GET',
        url: `${boardBaseUrl}/${roomId}/rentable-date`
      });

      if (rentableResult && rentableResult.success) {
        setRentableDate(rentableResult.rentableDate);
        rentableDateTemp = rentableResult.rentableDate;
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${rentableResult.error.code} - ${rentableResult.error.message}`}</p></div>, { type: 'error' });
      }

      const result = await request({
        method: 'GET',
        url: `${boardBaseUrl}/${roomId}?month=${currentDate}`
      })

      if (result && result.success) {
        const filteredList = result.days.map(day => {
          if (day.date === moment().format('YYYY-MM-DD')) {
            day.isToday = true
          }

          if (rentableDateTemp.request?.start && moment(rentableDateTemp.request.start).diff(moment(day.date).endOf('day')) <= 0 && moment(rentableDateTemp.request.end).diff(moment(day.date).startOf('day')) >= 0) {
            day.requestable = true
          }

          if (rentableDateTemp.draw?.start && moment(rentableDateTemp.draw.start).diff(moment(day.date).endOf('day')) <= 0 && moment(rentableDateTemp.draw.end).diff(moment(day.date).startOf('day')) >= 0) {
            day.drawable = true
          }

          return day
        })
        setDays(filteredList);
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, { type: 'error' });
      }

      const myRentResult = await request({
        method: 'GET',
        url: `${boardBaseUrl}/my`
      })

      if (myRentResult && myRentResult.success) {
        setMyRents(myRentResult.requests)
      } else {
        toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${myRentResult.error.code} - ${myRentResult.error.message}`}</p></div>, { type: 'error' });
      }

      if (checkAuthority('대관우선')) {
        const adminRentResult = await request({
          method: 'GET',
          url: `${boardBaseUrl}/admin`
        })

        if (adminRentResult && adminRentResult.success) {
          const adminRequests = adminRentResult.requests;

          let adminClubsDeduplicateds = {};

          for (let addMonth = 0; addMonth <= 4; addMonth++) {
            const month = moment().add(addMonth, 'month').format('YYYY-MM');

            adminClubsDeduplicateds[month] = {};
            for (const room of roomsTemp) {
              let individual = 0;
              const adminClubsAll = adminRequests
                .filter(o => moment(o.time_start).format('YYYY-MM') === month)
                .filter(o => o.room_id === room.id)
                .map(o => {
                  let club_name = o.club_name;
                  if (o.club_id === 0) {
                    individual = individual + 0.1;
                    o.club_id = individual;
                    club_name = `${o.club_name} (${o.name})`;
                  }

                  return { id: o.club_id, name: club_name }
                });
              const adminClubsDeduplicated = adminClubsAll.filter((o, i) => {
                return (
                  adminClubsAll.findIndex((p, j) => {
                    return o.id === p.id;
                  }) === i
                );
              });

              adminClubsDeduplicateds[month][room.id] = adminClubsDeduplicated;
            }
          }

          setAdminRents(adminRequests);
          setAdminClubs(adminClubsDeduplicateds);
        } else {
          toast(
            <div>
              <h3 className="font-bold">오류가 발생했습니다.</h3>
              <p>{`${adminRentResult.error.code} - ${adminRentResult.error.message}`}</p>
            </div>,
            { type: "error" }
          );
        }
      }
    })()
  }, [currentDate, roomId])

  const deleteRent = async () => {
    const result = await request({
      method: 'DELETE',
      url: `${boardBaseUrl}/${selectedRentId.roomId}/${selectedRentId.id}`
    })

    if (result && result.success) {
      toast('해당 대관 신청을 취소했습니다.', { type: 'success' });
      setCancelModal(false);
      setSelectedRentId(undefined);
      getDays();
    } else {
      toast(<div><h3 className="font-bold">오류가 발생했습니다.</h3><p>{`${result.error.code} - ${result.error.message}`}</p></div>, { type: 'error' });
    }
  }

  useEffect(() => {
    getDays();
  }, [getDays]);

  useEffect(() => {
    const rentsFiltered = adminRents
      .filter(o => moment(o.time_start).format('YYYY-MM') === moment(adminMonth).format('YYYY-MM'))
      .filter(o => o.room_id === adminRoomId)
      .filter(o => o.club_id === adminClubId);

    setAdminRentsFiltered(rentsFiltered);
  }, [adminRents, adminMonth, adminRoomId, adminClubId])

  return (
    <div className="max-w-7xl mx-auto px-4 my-8 lg:px-8">
      <div className="w-full">
        <PageHead
          title={boardName}
          description={boardDescription}
          to={boardBaseUrl}
        />
        <div className="bg-white shadow overflow-hidden rounded-md border border-stone-200">
          {view === "month" ? (
            <MonthCalendar
              days={days}
              setSearchParams={setSearchParams}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              rentableDate={rentableDate}
              rooms={rooms}
              roomId={roomId}
              setRoomId={setRoomId}
            />
          ) : (
            <DayCalendar
              days={days}
              setSearchParams={setSearchParams}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              rentableDate={rentableDate}
              rooms={rooms}
              roomId={roomId}
              setRoomId={setRoomId}
              getDays={getDays}
            />
          )}
        </div>

        {(myRents.length > 0) && (<div className="bg-white shadow overflow-hidden rounded-md mt-8">
          <div className="px-4 py-5 lg:px-8">
            <div className="-ml-4 -mt-2 flex items-center flex-wrap">
              <div className="w-full ml-4 mt-2">
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  나의 대관
                </h3>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-200 text-lg-wrapper h-full">
            <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-b-lg sm:-mx-6 md:mx-0">
              <table className="min-w-full w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="w-5/12 md:w-3/12 py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-gray-900">
                      동아리
                    </th>
                    <th className="w-2/12 md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                      대관장소
                    </th>
                    <th className="hidden md:table-cell md:w-2/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                      시작시간
                    </th>
                    <th className="hidden md:table-cell md:w-2/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                      종료시간
                    </th>
                    <th className="w-2/12 md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                      상태
                    </th>
                    <th className="w-2/12 md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                      취소
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {myRents.map((rent) => (
                    <tr key={rent.id}>
                      <td className="max-w-0 py-4 pl-8 pr-3 text-sm font-medium text-gray-900">
                        {rent.club_name}
                        <dl className="font-normal md:hidden">
                          <dd className="mt-1 truncate text-gray-600 cursor-pointer" onClick={() => setCurrentDate(moment(rent.time_start).format("YYYY-MM-DD"))}>
                            {moment(rent.time_start).format("MM-DD HH:mm")} ~
                          </dd>
                          <dd className="mt-1 truncate text-gray-600 cursor-pointer" onClick={() => setCurrentDate(moment(rent.time_end).format("YYYY-MM-DD"))}>
                            {moment(rent.time_end).add(1, 'second').format("MM-DD HH:mm")}
                          </dd>
                        </dl>
                      </td>
                      <td className="px-3 py-4 text-sm text-center text-gray-500">
                        {rooms.find((room) => room.id === rent.room_id)?.room}
                      </td>
                      <td className="px-3 py-4 text-sm text-center text-gray-500 cursor-pointer hidden md:table-cell" onClick={() => setCurrentDate(moment(rent.time_start).format("YYYY-MM-DD"))}>
                        {moment(rent.time_start).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td className="px-3 py-4 text-sm text-center text-gray-500 cursor-pointer hidden md:table-cell" onClick={() => setCurrentDate(moment(rent.time_end).format("YYYY-MM-DD"))}>
                        {moment(rent.time_end).add(1, 'second').format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td className="px-3 py-4 text-sm text-center text-gray-500">
                        {
                          {
                            Accepted: "신청됨",
                            Waiting: "예약중",
                            Failed: "탈락됨",
                            Canceled: "취소됨",
                            Rejected: "거절됨",
                            inProgress: "처리중",
                          }[rent.status]
                        }
                      </td>
                      <td className="py-4 pl-3 pr-4 text-sm text-center font-medium sm:pr-6">
                        {["Accepted", "Waiting", "inProgress"].includes(
                          rent.status
                        ) && (
                            <div
                              className="text-rose-600 hover:text-rose-800 cursor-pointer"
                              onClick={() => {
                                setSelectedRentId({ id: rent.id, roomId: rent.room_id });
                                setCancelModal(true);
                              }}
                            >
                              취소
                            </div>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>)}

        {checkAuthority("대관우선") && (
          <div className="bg-white shadow overflow-hidden rounded-md mt-8">
            <div className="px-4 py-5 lg:px-8">
              <div className="-ml-4 -mt-2 flex items-center flex-wrap">
                <div className="w-full ml-4 mt-2 flex justify-between">
                  <h3 className="text-xl flex items-center font-medium text-gray-900">
                    대관 관리
                  </h3>
                  <div className="w-max lg:flex">
                    <div className='inline-block lg:block'>
                      <Listbox value={adminMonth} onChange={setAdminMonth}>
                        {({ open }) => (
                          <>
                            <div className="relative">
                              <Listbox.Button className="bg-white relative h-10 w-32 lg:w-28 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm">
                                <span className="block truncate">
                                  {adminMonth}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute w-full z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                  {[0, 1, 2, 3].map((addMonth) => (
                                    <Listbox.Option
                                      key={moment().add(addMonth, 'month').format('YYYY-MM')}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-rose-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={moment().add(addMonth, 'month').format('YYYY-MM')}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected ? "font-semibold" : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {moment().add(addMonth, 'month').format('YYYY-MM')}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? "text-white" : "text-rose-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="ml-2 lg:ml-3 inline-block lg:block">
                      <Listbox value={adminRoomId} onChange={setAdminRoomId}>
                        {({ open }) => (
                          <>
                            <div className="relative">
                              <Listbox.Button className="bg-white relative h-10 w-32 lg:w-28 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm">
                                <span className="block truncate">
                                  {rooms.find((room) => room.id === adminRoomId)?.room}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute w-full z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                  {rooms.map((room) => (
                                    <Listbox.Option
                                      key={room.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-rose-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={room.id}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected ? "font-semibold" : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {room.room}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? "text-white" : "text-rose-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="ml-2 lg:ml-3 mt-2 lg:mt-0">
                      <Listbox value={adminClubId} onChange={setAdminClubId}>
                        {({ open }) => (
                          <>
                            <div className="relative">
                              <Listbox.Button className="bg-white relative h-10 w-64 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm">
                                <span className="block truncate">
                                  {
                                    adminClubs[adminMonth] && (
                                      adminClubs[adminMonth][adminRoomId].length ?
                                        (adminClubs[adminMonth][adminRoomId]?.find((club) => club.id === adminClubId)?.name ?? '동아리 선택') :
                                        '해당 기간 대관 기록 없음'
                                    )
                                  }
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute w-full z-50 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                  {adminClubs[adminMonth] && adminClubs[adminMonth][adminRoomId]?.map((club) => (
                                    <Listbox.Option
                                      key={club.id}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-rose-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-3 pr-9"
                                        )
                                      }
                                      value={club.id}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected ? "font-semibold" : "font-normal",
                                              "block truncate"
                                            )}
                                          >
                                            {club.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? "text-white" : "text-rose-600",
                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                              )}
                                            >
                                              <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b border-gray-200 text-lg-wrapper h-full">
              <div className="-mx-4 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 rounded-b-lg sm:-mx-6 md:mx-0" style={{ minHeight: '19rem' }}>
                <table className="min-w-full w-full divide-y divide-gray-300 border-b border-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="w-3/12 md:w-2/12 py-3.5 pl-8 pr-3 text-left text-sm font-semibold text-gray-900">
                        동아리
                      </th>
                      <th className="hidden md:table-cell md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        대관인
                      </th>
                      <th className="hidden md:table-cell md:w-2/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        연락처
                      </th>
                      <th className="w-2/12 md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        대관장소
                      </th>
                      <th className="w-3/12 md:hidden px-3 py-3.5 text-sm font-semibold text-gray-900">
                        시간
                      </th>
                      <th className="hidden md:table-cell md:w-2/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        시작시간
                      </th>
                      <th className="hidden md:table-cell md:w-2/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        종료시간
                      </th>
                      <th className="w-2/12 md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        상태
                      </th>
                      <th className="w-2/12 md:w-1/12 px-3 py-3.5 text-sm font-semibold text-gray-900">
                        취소
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {
                      adminRentsFiltered.length ?
                        adminRentsFiltered.sort((a, b) => a.time_start - b.time_start).map((rent) => (
                          <tr key={rent.id}>
                            <td className="max-w-0 py-4 pl-8 pr-3 text-sm font-medium text-gray-900">
                              {rent.club_name}
                              <br className="md:hidden" />
                              <span className="md:hidden text-gray-500">{rent.name}</span>
                              <br className="md:hidden" />
                              <span className="md:hidden text-gray-500">{rent.phone}</span>
                            </td>
                            <td className="px-3 py-4 text-sm text-center text-gray-500 hidden md:table-cell">
                              {rent.name}
                            </td>
                            <td className="px-3 py-4 text-sm text-center text-gray-500 hidden md:table-cell">
                              {rent.phone}
                            </td>
                            <td className="px-3 py-4 text-sm text-center text-gray-500">
                              {rooms.find((room) => room.id === rent.room_id)?.room}
                            </td>
                            <td
                              className="px-3 py-4 text-sm text-center text-gray-500 cursor-pointer md:table-cell"
                              onClick={() => { setRoomId(rent.room_id); setCurrentDate(moment(rent.time_start).format("YYYY-MM-DD")); setSearchParams({ view: "day" }); }}
                            >
                              <dl>
                                <dd className="hidden md:inline">{moment(rent.time_start).format("YYYY-MM-DD HH:mm")}</dd>
                              </dl>
                              <dl className="font-normal md:hidden">
                                <dd className="mt-1 truncate text-gray-600 cursor-pointer" onClick={() => setCurrentDate(moment(rent.time_start).format("YYYY-MM-DD"))}>
                                  {moment(rent.time_start).format("MM-DD HH:mm")} ~
                                </dd>
                                <dd className="mt-1 truncate text-gray-600 cursor-pointer" onClick={() => setCurrentDate(moment(rent.time_end).format("YYYY-MM-DD"))}>
                                  {moment(rent.time_end).add(1, 'second').format("MM-DD HH:mm")}
                                </dd>
                              </dl>
                            </td>
                            <td
                              className="px-3 py-4 text-sm text-center text-gray-500 cursor-pointer hidden md:table-cell"
                              onClick={() => { setRoomId(rent.room_id); setCurrentDate(moment(rent.time_end).format("YYYY-MM-DD")); setSearchParams({ view: "day" }); }}
                            >
                              {moment(rent.time_end).add(1, 'second').format("YYYY-MM-DD HH:mm")}
                            </td>
                            <td className="px-3 py-4 text-sm text-center text-gray-500">
                              {
                                {
                                  Accepted: "신청됨",
                                  Waiting: "예약중",
                                  Failed: "탈락됨",
                                  Canceled: "취소됨",
                                  Rejected: "거절됨",
                                  inProgress: "처리중",
                                }[rent.status]
                              }
                            </td>
                            <td className="py-4 pl-3 pr-4 text-sm text-center font-medium sm:pr-6">
                              {["Accepted", "Waiting", "inProgress"].includes(
                                rent.status
                              ) && (moment(rent.time_start).diff(moment()) > 0) && (
                                  <div
                                    className="text-rose-600 hover:text-rose-800 cursor-pointer"
                                    onClick={() => {
                                      setSelectedRentId({ id: rent.id, roomId: rent.room_id });
                                      setCancelModal(true);
                                    }}
                                  >
                                    취소
                                  </div>
                                )}
                            </td>
                          </tr>
                        )) :
                        (
                          <tr>
                            <td className="text-gray-500 h-64 text-center" colSpan="7">
                              동아리를 선택해주세요.
                            </td>
                          </tr>
                        )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        title={"해당 대관을 취소하시겠습니까?"}
        description={"취소한 대관 일정은 되돌릴 수 없습니다."}
        open={cancelModal}
        setOpen={setCancelModal}
        action={deleteRent}
        confirmBtnName="대관 취소"
        cancelBtnName="돌아가기"
      >
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </Modal>
    </div>
  );
}